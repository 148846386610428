@import url('https://fonts.googleapis.com/css2?family=Kollektif&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  font-family: 'kollektif', sans-serif;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/* Работает в Firefox */
.scrollStyle {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Работает в Chrome, Edge и Safari */
.scrollStyle::-webkit-scrollbar {
  width: 12px;
}

.scrollStyle::-webkit-scrollbar-track {
  background: orange;
}

.scrollStyle::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  /* height: 80px; */
}

.spinner-div {
  position: absolute;
  top: -5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.spinner div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.spinner div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.spinner div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.spinner div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* 
@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
} */

/* .pulse-spinner {
  width: 50px;
  height: 50px;
  animation: pulse 1.5s ease-in-out infinite;
}

.pulse-spinner-little {
  width: 24px;
  height: 24px;
  animation: pulse 1.5s ease-in-out infinite;
} */


.element-1 {
  position: relative;
}

.element-1+.element-2::before {
  content: "";
  display: block;
  border: 1px solid black;
  margin-top: 10px;
}

.element-1:first-child+.element-2::before {
  content: "";
  display: block;
  border: 1px solid black;
  margin-top: 10px;
}

.line-1 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 45px;
  left: 15px;

}

.line-2 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 165px;
  left: 15px;
}

.line-3 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 285px;
  left: 15px;
}

.line-4 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 405px;
  left: 15px;
}

.line-5 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 525px;
  left: 15px;
}

.line-6 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 645px;
  left: 15px;
}

.line-7 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 765px;
  left: 15px;
}

.line-8 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 885px;
  left: 15px;
}

.line-9 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 1005px;
  left: 15px;
}

.line-10 {
  border-left: 3px solid rgb(255, 255, 255);
  top: 1125px;
  left: 15px;
}

.spinnerDark {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: spinnerD 1000ms linear infinite;
  top: 50%;
  margin: -8px auto 0;
}

.spinnerLight {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: spinnerL 1000ms linear infinite;
  top: 50%;
  margin: -8px auto 0;
}

@keyframes spinnerD {
  0% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.05), 19px -19px 0 0 rgba(36, 36, 36, 0.1), 27px 0 0 0 rgba(36, 36, 36, 0.2), 19px 19px 0 0 rgba(36, 36, 36, 0.3), 0 27px 0 0 rgba(36, 36, 36, 0.4), -19px 19px 0 0 rgba(36, 36, 36, 0.6), -27px 0 0 0 rgba(36, 36, 36, 0.8), -19px -19px 0 0 rgba(36, 36, 36);
  }

  12.5% {
    box-shadow: 0 -27px 0 0 rgb(36, 36, 36), 19px -19px 0 0 rgba(36, 36, 36, 0.05), 27px 0 0 0 rgba(36, 36, 36, 0.1), 19px 19px 0 0 rgba(36, 36, 36, 0.2), 0 27px 0 0 rgba(36, 36, 36, 0.3), -19px 19px 0 0 rgba(36, 36, 36, 0.4), -27px 0 0 0 rgba(36, 36, 36, 0.6), -19px -19px 0 0 rgba(36, 36, 36, 0.8);
  }

  25% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.8), 19px -19px 0 0 rgb(36, 36, 36), 27px 0 0 0 rgba(36, 36, 36, 0.05), 19px 19px 0 0 rgba(36, 36, 36, 0.1), 0 27px 0 0 rgba(36, 36, 36, 0.2), -19px 19px 0 0 rgba(36, 36, 36, 0.3), -27px 0 0 0 rgba(36, 36, 36, 0.4), -19px -19px 0 0 rgba(36, 36, 36, 0.6);
  }

  37.5% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.6), 19px -19px 0 0 rgba(36, 36, 36, 0.8), 27px 0 0 0 rgb(36, 36, 36), 19px 19px 0 0 rgba(36, 36, 36, 0.05), 0 27px 0 0 rgba(36, 36, 36, 0.1), -19px 19px 0 0 rgba(36, 36, 36, 0.2), -27px 0 0 0 rgba(36, 36, 36, 0.3), -19px -19px 0 0 rgba(36, 36, 36, 0.4);
  }

  50% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.4), 19px -19px 0 0 rgba(36, 36, 36, 0.6), 27px 0 0 0 rgba(36, 36, 36, 0.8), 19px 19px 0 0 rgb(36, 36, 36), 0 27px 0 0 rgba(36, 36, 36, 0.05), -19px 19px 0 0 rgba(36, 36, 36, 0.1), -27px 0 0 0 rgba(36, 36, 36, 0.2), -19px -19px 0 0 rgba(36, 36, 36, 0.3);
  }

  62.5% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.3), 19px -19px 0 0 rgba(36, 36, 36, 0.4), 27px 0 0 0 rgba(36, 36, 36, 0.6), 19px 19px 0 0 rgba(36, 36, 36, 0.8), 0 27px 0 0 rgb(36, 36, 36), -19px 19px 0 0 rgba(36, 36, 36, 0.05), -27px 0 0 0 rgba(36, 36, 36, 0.1), -19px -19px 0 0 rgba(36, 36, 36, 0.2);
  }

  75% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.2), 19px -19px 0 0 rgba(36, 36, 36, 0.3), 27px 0 0 0 rgba(36, 36, 36, 0.4), 19px 19px 0 0 rgba(36, 36, 36, 0.6), 0 27px 0 0 rgba(36, 36, 36, 0.8), -19px 19px 0 0 rgb(36, 36, 36), -27px 0 0 0 rgba(36, 36, 36, 0.05), -19px -19px 0 0 rgba(36, 36, 36, 0.1);
  }

  87.5% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.1), 19px -19px 0 0 rgba(36, 36, 36, 0.2), 27px 0 0 0 rgba(36, 36, 36, 0.3), 19px 19px 0 0 rgba(36, 36, 36, 0.4), 0 27px 0 0 rgba(36, 36, 36, 0.6), -19px 19px 0 0 rgba(36, 36, 36, 0.8), -27px 0 0 0 rgb(36, 36, 36), -19px -19px 0 0 rgba(36, 36, 36, 0.05);
  }

  100% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.05), 19px -19px 0 0 rgba(36, 36, 36, 0.1), 27px 0 0 0 rgba(36, 36, 36, 0.2), 19px 19px 0 0 rgba(36, 36, 36, 0.3), 0 27px 0 0 rgba(36, 36, 36, 0.4), -19px 19px 0 0 rgba(36, 36, 36, 0.6), -27px 0 0 0 rgba(36, 36, 36, 0.8), -19px -19px 0 0 rgb(36, 36, 36);
  }
}

@keyframes spinnerL {
  0% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.05), 19px -19px 0 0 rgba(221, 221, 221, 0.1), 27px 0 0 0 rgba(221, 221, 221, 0.2), 19px 19px 0 0 rgba(221, 221, 221, 0.3), 0 27px 0 0 rgba(221, 221, 221, 0.4), -19px 19px 0 0 rgba(221, 221, 221, 0.6), -27px 0 0 0 rgba(221, 221, 221, 0.8), -19px -19px 0 0 rgba(221, 221, 221);
  }

  12.5% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221), 19px -19px 0 0 rgba(221, 221, 221, 0.05), 27px 0 0 0 rgba(221, 221, 221, 0.1), 19px 19px 0 0 rgba(221, 221, 221, 0.2), 0 27px 0 0 rgba(221, 221, 221, 0.3), -19px 19px 0 0 rgba(221, 221, 221, 0.4), -27px 0 0 0 rgba(221, 221, 221, 0.6), -19px -19px 0 0 rgba(221, 221, 221, 0.8);
  }

  25% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.8), 19px -19px 0 0 rgba(221, 221, 221), 27px 0 0 0 rgba(221, 221, 221, 0.05), 19px 19px 0 0 rgba(221, 221, 221, 0.1), 0 27px 0 0 rgba(221, 221, 221, 0.2), -19px 19px 0 0 rgba(221, 221, 221, 0.3), -27px 0 0 0 rgba(221, 221, 221, 0.4), -19px -19px 0 0 rgba(221, 221, 221, 0.6);
  }

  37.5% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.6), 19px -19px 0 0 rgba(221, 221, 221, 0.8), 27px 0 0 0 rgba(221, 221, 221), 19px 19px 0 0 rgba(221, 221, 221, 0.05), 0 27px 0 0 rgba(221, 221, 221, 0.1), -19px 19px 0 0 rgba(221, 221, 221, 0.2), -27px 0 0 0 rgba(221, 221, 221, 0.3), -19px -19px 0 0 rgba(221, 221, 221, 0.4);
  }

  50% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.4), 19px -19px 0 0 rgba(221, 221, 221, 0.6), 27px 0 0 0 rgba(221, 221, 221, 0.8), 19px 19px 0 0 rgba(221, 221, 221), 0 27px 0 0 rgba(221, 221, 221, 0.05), -19px 19px 0 0 rgba(221, 221, 221, 0.1), -27px 0 0 0 rgba(221, 221, 221, 0.2), -19px -19px 0 0 rgba(221, 221, 221, 0.3);
  }

  62.5% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.3), 19px -19px 0 0 rgba(221, 221, 221, 0.4), 27px 0 0 0 rgba(221, 221, 221, 0.6), 19px 19px 0 0 rgba(221, 221, 221, 0.8), 0 27px 0 0 rgba(221, 221, 221), -19px 19px 0 0 rgba(221, 221, 221, 0.05), -27px 0 0 0 rgba(221, 221, 221, 0.1), -19px -19px 0 0 rgba(221, 221, 221, 0.2);
  }

  75% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.2), 19px -19px 0 0 rgba(221, 221, 221, 0.3), 27px 0 0 0 rgba(221, 221, 221, 0.4), 19px 19px 0 0 rgba(221, 221, 221, 0.6), 0 27px 0 0 rgba(221, 221, 221, 0.8), -19px 19px 0 0 rgba(221, 221, 221), -27px 0 0 0 rgba(221, 221, 221, 0.05), -19px -19px 0 0 rgba(221, 221, 221, 0.1);
  }

  87.5% {
    box-shadow: 0 -27px 0 0 rgba(221, 221, 221, 0.1), 19px -19px 0 0 rgba(221, 221, 221, 0.2), 27px 0 0 0 rgba(221, 221, 221, 0.3), 19px 19px 0 0 rgba(221, 221, 221, 0.4), 0 27px 0 0 rgba(221, 221, 221, 0.6), -19px 19px 0 0 rgba(221, 221, 221, 0.8), -27px 0 0 0 rgba(221, 221, 221), -19px -19px 0 0 rgba(221, 221, 221, 0.05);
  }

  100% {
    box-shadow: 0 -27px 0 0 rgba(36, 36, 36, 0.05), 19px -19px 0 0 rgba(36, 36, 36, 0.1), 27px 0 0 0 rgba(36, 36, 36, 0.2), 19px 19px 0 0 rgba(36, 36, 36, 0.3), 0 27px 0 0 rgba(36, 36, 36, 0.4), -19px 19px 0 0 rgba(36, 36, 36, 0.6), -27px 0 0 0 rgba(36, 36, 36, 0.8), -19px -19px 0 0 rgba(221, 221, 221);
  }
}